<template>
  <v-menu v-model="stickerDialog" top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="onSelectSticker" v-bind="attrs" v-on="on">
        <v-icon v-text="'mdi-sticker-emoji'" size="20" color="white" />
      </v-btn>
    </template>
    <v-card style="width: 300px; height: 400px; overflow: auto">
      <v-card-text
        class="d-flex mx-0"
        style="flex-wrap: wrap;padding-left:3px; padding-right: 0px"
      >
        <div
          v-for="sticker in stickers"
          :key="sticker"
          class="sticker-item"
          @click="onSend(sticker)"
        >
          <v-img
            :src="require(`@/assets/sticker/suricata/${sticker}.png`)"
            contain
          />
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    onSend: Function,
  },
  data() {
    return {
      stickerDialog: false,
      stickers: [
        "Cook",
        "Cool",
        "Cry",
        "Eat",
        "go",
        "help",
        "hope",
        "How are you",
        "I made it",
        "Im going there",
        "Laughs",
        "Love",
        "ok",
        "Ooops",
        "Plant a plant",
        "Sad",
        "Send me a ticket",
        "Shy",
        "Sleep",
        "Thank you",
        "Ticket",
        "welcome",
        "What",
      ],
    };
  },
  methods: {
    onSelectSticker() {
      this.stickerDialog = true;
    },
  },
};
</script>
<style>
.sticker-item {
  width: 70px;
  height: 70px;
  padding: 2px;
}
.sticker-item:hover {
  background: lightblue;
  border-radius: 10px;
}
</style>
